<div class="select__form" [ngClass]="{'p-1': !isWhiteBox, 'd-flex': display === 'horizontal'}">
    @if (selectLabel) {
        <label class="fw-medium p-1 custom-reactive-select-label" [style]="labelStyle" [ngClass]="display === 'horizontal' ? 'so-primary so-text-3-bold pe-3' : 'custom-reactive-select-label'"> 
            {{ selectLabel }} 
            <app-so-required-mark [show]="requiredMark" [isValid]="touched && valid" />
        </label>
    }
    <div class="select-container" [ngClass]="{'white-box': isWhiteBox}">
        <select #selectElement (change)="updateValue($event)" [value]="value" [disabled]="isDisabled" [name]="name"
                [class.custom-reactive-select-arrow]="!isLoading"
                class="custom-reactive-select custom-reactive-select-arrow form-select form-select-sm app-so-input-border w-100"
                [style]="customStyle"
                [matTooltip]="(tooltipDisabledText && isDisabled)  ? tooltipDisabledText : ''"
                matTooltipPosition="above">
            @if (!isWhiteBox) {
                <option value="" hidden> {{ 'selected' | translated : 'Seleccionar' }} </option>
            }
            @if (addSelectOption) {
                <option value="null">{{ 'selected' | translated : 'Seleccionar' }}</option>
            }
            @for (groupKey of getBotTypeKeys(items); track $index) {
                <optgroup [label]="groupKey">
                    @for (item of items[groupKey]; track $index) {
                        <option [value]="item.key" [disabled]="shouldBeDisabled(item.key)" [selected]="item.key == selectedItemId">
                            {{ item.value | translate }}
                        </option>
                    }
                </optgroup>
            }
        </select>
        @if (isLoading) {
            <div class="spinner"></div>
        }
    </div>
</div>