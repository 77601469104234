import { Injectable, inject } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService } from '../services/storages.service';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
  private readonly storageService = inject(StorageService);
  private readonly excludedUrls = ['https://qa4.sicopweb.com/api/omnicanal/conversaciones/getConversationsPlayground'];


  private handleRequestWithToken(request: HttpRequest<unknown>, next: HttpHandler, token: string | null): Observable<HttpEvent<unknown>> {
    request = request.clone({
      setHeaders: {
        Authorization: token ? `Bearer ${token}` : '',
        mode: 'cors',
      },
    });
  
    return next.handle(request);
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {

    const isExcludedUrl = this.excludedUrls.some(url => request.url.includes(url));
    
    if (
      request.url.includes('/assets/') ||
      request.url.includes('/automapeomdm-dev/') ||
      request.url.includes('https://qa2.sicopweb.com/') ||
      request.url.includes('https://qa3.sicopweb.com/') ||
      request.url.includes('https://api.sicopweb.com/funnel/dev') ||
      request.url.includes('https://qa1.sicopweb.com/SeekopBusinessAPI/Mty') ||
      request.url.includes('https://www.sicopweb.com/Api/Multicanal/Whatsapp/') ||
      request.url.includes('https://www.sicopweb.com/Api/Multicanal/Meta/') ||
      request.url.includes('https://qa.sicopweb.com/Api/Bot') ||
      request.url.includes('https://seekop10.sicopweb.net/api/10.0.0/global/periodotiempo/') ||
      request.url.includes('https://seekop10.sicopweb.net/api/10.0.0/ia/plantilla/') ||
      request.url.includes('https://seekop10.sicopweb.net/api/10.0.0/global/catalogos/empresa/') ||
      request.url.includes('https://seekop10.sicopweb.net/api/10.0.0/documentos') ||
      request.url.includes('https://seekop10.sicopweb.net/api/10.0.0/promocionia/') ||
      request.url.includes('https://seekop10.sicopweb.net/api/10.0.0/ventas/catalogos/nuevos/') ||
      request.url.includes('https://seekop10.sicopweb.net/api/10.0.0/bitacora') ||
      request.url === 'https://www.sicopweb.com/seekopia/1.0/process/MapearExcel' ||
      request.url === 'https://api.sicopweb.com/auth/v3/token' ||
      request.url === 'https://qa.sicopweb.com/auth/10.0.0/login' ||
      isExcludedUrl
    )
      return next.handle(request);
    
    const token =
      this.storageService.getCookie('token') ||
      this.storageService.getLocalStorage('token');

    if (token) {
      return this.handleRequestWithToken(request, next, token);
    } else {
      return next.handle(request);
    } 
  }
}
