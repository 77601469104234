<div class="{{ paddingClasses }} input__form"
  [ngClass]="(formGroup ? (formGroup.get(name)?.valid && !formGroup.get(name)?.touched ? 'valid':'invalid' ):null)">
  @if (label != '') {
    <label class="{{ paddingClasses }} so-text-3" [style]="labelStyle">{{label}}<app-so-required-mark
      [show]="requiredMark" /></label>
  }
  @if (formGroup) {
    <textarea [formControlName]="formGroup ?name:null" class="form-control" [rows]="rows" [attr.id]="inputId"
      [placeholder]="placeholder" [value]="text" [disabled]="disabled" [style]="areaStyles"
      [attr.maxlength]="maxlength ? maxlength : null" [maxLength]="maxlength" (input)="updateValue(getValue($event))"
      [SanitizeInput]="applySanitize" name="{{name}}">
      {{text}}
    </textarea>
  }@else{
    <textarea class="form-control" [rows]="rows" [attr.id]="inputId"
      [placeholder]="placeholder" [value]="text" [disabled]="disabled" [style]="areaStyles"
      [attr.maxlength]="maxlength ? maxlength : null" [maxLength]="maxlength" (input)="updateValue(getValue($event))"
      [SanitizeInput]="applySanitize" name="{{name}}">
      {{text}}
    </textarea>
  }
</div>
