@if (tabsDisplay == 'horizontal') {
  <div class="h-100 tabs-container">
    <div class="d-flex">
    @for (tab of tabs; track $index) {
      <button
        class="nav-link d-flex align-items-center justify-content-center"
        [ngClass]="{
          'active': $index === activeTabIndex,
          'principal': type == 'principal',
          'tab-shadow': type != 'principal',
          'size-button': tab.styleButton
          }"
        [matTooltip]="(tooltipDisabledText != '' && tab.disabled) ? tooltipDisabledText : ''"
        [style]="tab.styleButton"
        (click)="setActiveTab($index)"
        [routerLink]="tab.route"
        [queryParams]="{ token: tokenService.urlToken }"
        [disabled]="tab.disabled">
        @if (tab.icon) {
          <app-so-svg-icon height="auto" [src]="'assets/img/' + tab.icon + '.svg'" [applyChange]="true" [size]="tab.iconsize ? tab.iconsize : 1"
          [color]="'--so-secondary'"></app-so-svg-icon>
        }
        <span class="m-0 tab-title truncate-text" [style]="tab.styleTextSpan" [ngClass]="type == 'principal' ? 'so-subtitle' : 'so-text-2-bold'" [title]="tab.title" >{{tab.title}}</span>
        @if(tab.showIconUploadding){
          @if(!isUploading || !isUploadingTextFile){
            <div class="upload-container" [class.uploading]="!isUploading || !isUploadingTextFile">
              <so-icon 
                [svg]="'mdi-cloud-upload-outline-green'" 
                [size]=".7"
                [color]="'--color-icon'"
                [applyChange]="true"
                >
              </so-icon>
            </div>
          }
        
        }
      </button>
    }
    </div>
    <div class="tab-content" [ngClass]="type == 'principal' ? 'principal' : ''" [style]="type == 'principal' ? '': 'box-shadow: var(--box-shadow);'">
      <router-outlet></router-outlet>
    </div>
  </div>
}
@if (tabsDisplay == 'vertical') {
  <div class="d-flex h-100">
    <ul class="nav nav-pills flex-column" role="tablist">
      @for (tab of tabs; track $index) {
        <li class="nav-item" role="presentation">
          <button
            class="nav-link d-flex align-items-center"
            [ngClass]="$index === 0 ? 'active' : ''"
            id="{{tab.title}}-tab"
            data-bs-toggle="tab"
            [attr.data-bs-target]="'#' + tab.title + '-tab-pane'"
            type="button"
            role="tab"
            [attr.aria-controls]="tab.title + '-tab-pane'"
            [attr.aria-selected]="$index === 0"
            (click)="setActiveTab($index)"
            [queryParams]="{ token: tokenService.urlToken }"
            [routerLink]="tab.route">
            @if (tab.icon){
              <app-so-svg-icon [src]="'assets/img/' + tab.icon + '.svg'" [applyChange]="true" [size]="tab.iconsize ? tab.iconsize : 1"
              [color]="'--color-icon'"></app-so-svg-icon>
            }
            <p class="m-0">{{tab.title}}
            <div class="circleActive"></div>
          </button>
        </li>
      }
    </ul>
    <div class="tab-content">
      <router-outlet></router-outlet>
    </div>
  </div>
}
@if (tabsDisplay == 'sections') {
  <div class="h-100 container-sections">
    <ul class="nav flex-column gap-2">
      @for (tab of tabs; track $index) {
        <li class="nav-item" role="presentation">
          <app-so-card-bg-multi
            [ngClass]="{'disab': tab.disabled}"
            typeCard="Tab"
            data-bs-toggle="tab"
            (click)="setActiveTab($index)"
            [title]="tab.title"
            [bgColor]="$index== 0 ? 'FB' : $index == 1 ? 'WHATS' : $index == 2 ? 'SMS' : 'FB'"
            [active]="$index === activeTabIndex"
            [img]="tab.icon ? tab.icon : ''"
            [iconSize]="tab.iconsize ? tab.iconsize : 1"
            [routerLink]="tab.route"
            [queryParams]="{ token: tokenService.urlToken }">
          </app-so-card-bg-multi>
        </li>
      }
    </ul>
    @if (contentCard) {
      <app-card class="tab-content sections">
        <div class="p-4 h-100">
          <router-outlet></router-outlet>
        </div>
      </app-card>
    }@else {
      <div class="h-100">
        <router-outlet></router-outlet>
      </div>
    }
  </div>
}
@if (tabsDisplay == 'steps') {
  <app-card>
    <div class="container-steps">
      <div class="stepper-container">
        @for (tab of tabs; track $index) {
          <div class="tab-item">
            <div *ngIf="tabs.length == 2 && $index != 0" class="line"></div> <!-- Línea entre tabs -->
            <button
              class="step-button d-flex align-items-center justify-content-center"
              (click)="setActiveTab($index)"
              [routerLink]="tab.route"
              [queryParams]="{ token: tokenService.urlToken }"
              [disabled]="tab.disabled"
              [matTooltip]="(tooltipDisabledText != '' && tab.disabled) ? tooltipDisabledText : ''">
              <app-so-badge
                [isCircle]="true"
                [color]="activeTabIndex == $index ? 'second-white':'grey-light' "
                [text]="$index + 1"
              ></app-so-badge>
              <span class="ms-2" [style]="tab.styleTextSpan">{{ tab.title }}</span>
            </button>
            <div *ngIf="$index < tabs.length - 1" class="line"></div> <!-- Línea entre tabs -->
          </div>
        }
      </div>
      <div class="">
        <router-outlet></router-outlet>
      </div>
    </div>
  </app-card>
}
