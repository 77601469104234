import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-so-ia-prompt',
  templateUrl: './so-ia-prompt.component.html',
  styleUrl: './so-ia-prompt.component.scss'
})
export class SoIaPromptComponent {
  @Input() visible = false;
  @Output() onTouchedButton = new EventEmitter<string>();
  public areaText: string = '';
  public isFirstImproved: boolean = true;
  onClick(){
    if(this.areaText == '') return;
    this.isFirstImproved =  false;
    this.onTouchedButton.emit(this.areaText);
    this.areaText = '';
  }
}
