import { Injectable, inject } from '@angular/core';
import { Request } from '@core/models/requests.model';
import { Translate } from '@core/interfaces/catalogos.intefaces';
import { StorageService } from '@core/services/storages.service';

@Injectable({
  providedIn: 'root',
})
export class TranslateService {
  private url = `global/etiqueta/`;
  private readonly request = inject(Request);
  private readonly storageService = inject(StorageService);
  public labels = this.storageService.getLocalStorage('Langs');
  public lang = localStorage.getItem('browserLang');
  private idioma = this.lang ? this.lang : 'es';

  getLabels(lang: string) {
    const options = {
      headers: this.request.headers,
    };
    return this.request.Get<Translate>(`${this.url + lang}`, options);
  }

  applyLang() {
    const idiomas = ['ES', 'EN'];
    let idiomasRespuestas: LangResponse[] = [];
    idiomas.forEach((e) => {
      this.getLabels(e).subscribe({
        next: (data: Translate) => {
          const storedLangs: LangResponse[] = this.storageService.getLocalStorage('Langs') || [];
          idiomasRespuestas = storedLangs.filter((langObj: LangResponse) => langObj.lang !== e.toLowerCase());
          idiomasRespuestas.push({
            lang: e.toLowerCase(),
            response: data.response,
          });
          this.storageService.setLocalStorage('Langs', idiomasRespuestas);
        },
        error: () => {},
      });
    });
  }

  instant(idEtiqueta: string, fallback: string = idEtiqueta, lang: string = ''): any {    
    const response = this.labels?.find(
      (obj: { lang: string; response: [{}] }) => lang === '' ? obj.lang === this.idioma : obj.lang == lang
    )
    .response.etiquetas?.find(
      (lang: { idEtiqueta: string }) => lang.idEtiqueta === idEtiqueta
    )?.etiqueta ?? fallback;
    return response;
  }
}

interface LangResponse {
  lang: string;
  response: any;
}
