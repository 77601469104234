import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-so-user-icon',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './so-user-icon.component.html',
  styleUrl: './so-user-icon.component.scss'
})
export class SoUserIconComponent {
  @Input() data: any;
  @Input() size: number = 31;

  obtenerLetraNombreApellido(nombreCompleto: string): string {
    const partesNombre = nombreCompleto.trim().split(' ');
    const primeraLetraNombre = partesNombre[0].charAt(0);
    const primerApellido =
      partesNombre.length > 1 ? partesNombre[partesNombre.length - 1] : '';
    const primeraLetraApellido = primerApellido.charAt(0);
    return `${primeraLetraNombre}${primeraLetraApellido}`;
  }

  setColorForCircle(prospecto: any): string {
    if (typeof prospecto.status !== 'undefined' && prospecto.status === 'Rechazado') {
      return 'circle-color-declined';
    } else if(typeof prospecto.status !== 'undefined' && prospecto.status.toLowerCase() === 'post-venta'){
      return 'circle-color-after-sale';
    } else if (typeof prospecto.prospectoAtendido !== 'undefined' && !prospecto.prospectoAtendido) {
      return 'circle-color-not-attended';
    } else if (typeof prospecto.estado !== 'undefined' && prospecto.estado === 'Activo') {
      return 'circle-color-active';
    } else if (typeof prospecto.estado !== 'undefined' && prospecto.estado === 'Inactivo') {
      return 'circle-color-inactive';
    } else {
      return 'circle-color-active';
    }
  }
}
