import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'so-drag-drop-list',
  templateUrl: './so-drag-drop-list.component.html',
  styleUrl: './so-drag-drop-list.component.scss'
})
export class SoDragDropListComponent {

  @Output() itemsSelectChange = new EventEmitter<any[]>();

  @Input() draggableItems: boolean = false; 
  @Input() titleAvailableItems: string = '';
  @Input() titleSelectedItems: String = '';
  @Input() tablesWidth: string = 'auto';
  @Input() tablesHeight: string = '200px';
  @Input() showButtons?: boolean = false;
  
  @Input() set itemsList(value: { key: any, value: string }[]) {
    if (value) {
      this.items = value.map(item => ({ ...item, draggable: true }));
    } else {
      this.items = [];
    }
  }
  get itemsList() {
    return this.items;
  }

 items: { key: any, value: string, draggable: boolean }[] = [];

 @Input() set defaultSelect(value: { key: any, value: string }[]) {
    this.itemsSelected = value ? value.map(item => ({ ...item, draggable: this.draggableItems })) : [];
  }

  itemsSelected: { key: any, value: string, draggable: boolean }[] = [];
  selectedItem: { key: any, value: string, draggable: boolean } | null = null;

  removeItem() {
    if (this.selectedItem) {
      if(!this.items.some( item => item.value === this.selectedItem?.value )){
        this.items.push(this.selectedItem);
        this.itemsSelected = this.itemsSelected.filter(item => item !== this.selectedItem);
        this.selectedItem = null;
        this.emitItemsSelected();
      }
    }
  }

  addItem() {
    if (this.selectedItem) {
      if( !this.itemsSelected.some( item => item.value === this.selectedItem?.value )){
        this.itemsSelected.push(this.selectedItem);
        this.items = this.items.filter(item => item !== this.selectedItem);
        this.selectedItem = null;
        this.emitItemsSelected();
      }
    }
  }

  addAllItems(){
    this.itemsSelected.push(...this.items);
    this.items = [];
    this.emitItemsSelected();
  }

  removeAllItems(){
    const draggableItems = this.itemsSelected.filter(item => item.draggable);
    this.items.push(...draggableItems);
    this.itemsSelected = this.itemsSelected.filter(item => !item.draggable);
    this.emitItemsSelected();
  }

  selectItem(item: { key: any, value: string, draggable: boolean }): void {
    this.selectedItem = item;    
  }

  private emitItemsSelected() {
    const clonedItems = this.itemsSelected.map( item => ({ key: item.key, value: item.value}) )
    this.itemsSelectChange.emit(clonedItems);
  }

  onDragStart(event: any, item: { key: any, value: string }) {
    event.dataTransfer.setData('item', JSON.stringify(item));
  }

  onDragOver(event: any) {
      event.preventDefault();
  }

  onDrop(event: any, list: string) {
    event.preventDefault();
    const itemData = JSON.parse(event.dataTransfer.getData('item'));
    if (list === 'itemsList') {
        const index = this.itemsSelected.findIndex(item => item.value === itemData.value);
        if (index !== -1) {
            this.items.push({ ...this.itemsSelected[index] });
            this.itemsSelected.splice(index, 1);
            this.emitItemsSelected();
        }
    } else {
        const { value } = itemData;
        const index = this.items.findIndex(item => item.value === value);
        if (index !== -1) {
            this.itemsSelected.push({ ...this.items[index] });
            this.items.splice(index, 1);
            this.emitItemsSelected();
        }
    }
  }
  
}