<div class="backdrop" #backdrop (click)="closeAlert($event)">

  <div class="alert" [style.width]="width">
    <div class="success-symbol">

      <svg class="icon checkmark" xmlns="http://www.w3.org/2000/svg" *ngIf="alertType === 'success' && animate"
        viewBox="0 0 52 52">
        <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
        <path class="checkmark__check" fill="none" stroke="#fff" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
      </svg>

      <svg class="icon cross" xmlns="http://www.w3.org/2000/svg" *ngIf="alertType === 'error' && animate"
        viewBox="0 0 52 52">
        <circle class="cross__circle" cx="26" cy="26" r="25" fill="none" />
        <path class="cross__check" fill="none" stroke="#fff" d="M16 16 L36 36 M36 16 L16 36" />
      </svg>

      <svg class="icon warning" xmlns="http://www.w3.org/2000/svg" *ngIf="alertType === 'warning' && animate"
        viewBox="0 0 52 52">
        <circle class="warning__circle" cx="26" cy="26" r="25" fill="none" />
        <path class="warning__check" fill="none" stroke="#fff" d="M26 15 v20 M26 40 v2" />
      </svg>

      <svg class="icon info" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" *ngIf="alertType === 'info'">
        <path class="" fill="var(--color-icon)" d="M13,9H11V7H13M13,17H11V11H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
      </svg>

      <div class="icon__no-animate" *ngIf="alertType === 'warning' && !animate">
        <img src="./assets/img/mdi-report-outline.svg" alt="">
        <div>
          <h4>{{title}}</h4>
        </div>
      </div>

    </div>
    <div class="body">
      <h2 *ngIf="title && animate">{{title}}</h2>
      <div class="message" *ngIf="message && !inputAlert">
        <h4>{{message}}</h4>
      </div>
      <div class="description" [innerHTML]="htmlContent">
      </div>
      @if (inputAlert && inputTextFG) {
      <form [formGroup]="inputTextFG">
        <so-input-reactive-form
          formControlName="text"
          type="text"
          [bigger]="true"
          placeholder="{{message}}"
        />
      </form>
      }
    </div>
    <div class="footer">
      @switch (alertType) {
      @case('warning'){
      @if (showButtons || showButtons == undefined) {
      <div class="content-buttons">
        <app-icon-button icon="highlight_off" matIconColor="so-danger" color="so-danger" (click)="onConfirm(false)"
          [text]="'cancel' | translated : 'Cancelar'"></app-icon-button>
        <span [ngClass]="{ 'disab': inputTextFG?.invalid ?? false}">
          <app-icon-button icon="check_circle_outline" matIconColor="so-secondary" color="so-secondary"
          (click)="onConfirm(true)" [text]="'accept' | translated : 'Aceptar'"></app-icon-button>
        </span>
      </div>
      }@else if (!showButtons && showButtonClose) {
      <div class="content-buttons">
        <app-icon-button icon="check_circle_outline" matIconColor="so-secondary" color="so-secondary"
          (click)="closeAlertButton()" [text]="'accept' | translated : 'Aceptar'"></app-icon-button>
      </div>
      }
      }
      @case ('success') {
      @if (nextButton) {
      <div class="content-buttons">
        <app-button [text]="'next' | translated: 'Siguiente'" svg="mdi-arrow-right-bold-circle" [type]="'button'"
          [iconAlignment]="'right'" [injection]="{ label: 'pt-2' }" [width]="'100%'" (click)="onConfirm(true)"
          [colorIcon]="'--so-secondary'" [applyChanges]="true"></app-button>
      </div>
      }@else if (showButtonClose) {
      <div class="content-buttons">
        <app-icon-button icon="check_circle_outline" matIconColor="so-secondary" color="so-secondary"
          (click)="closeAlertButton()" [text]="'accept' | translated : 'Aceptar'"></app-icon-button>
      </div>
      }
      }
      @case ('info') {
      @if (showButtons) {
      <div class="content-buttons">
        <app-icon-button icon="check_circle_outline" matIconColor="so-secondary" color="so-secondary" (click)="closeAlertButton()" [text]="'accept' | translated : 'Aceptar'">
        </app-icon-button>
      </div>
      }
      }
      }
    </div>
  </div>
</div>
