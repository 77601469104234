@if (visible) {
    <div class="ia-promt-container">
        <div class="flex-fill" style="padding: 5px;">
            <app-so-text-area 
                [paddingClasses]="'d-flex h-100'" 
                [placeholder]="'prompt_ia_placeholder' | translated: 'Escribe lo que quieras realizar con el texto'" 
                [(ngModel)]="areaText"
            ></app-so-text-area>
        </div>
        <div class="d-flex justify-content-end">
            <app-button 
                [text]="isFirstImproved ? ('save' | translated : 'Guardar') : ('update' | translated : 'Actualizar')" 
                [svg]="'mdi-creation'" 
                colorIcon="--so-secondary" 
                [applyChanges]="true"
                [injection]="{icon: 'margin-top: -5px; margin-right: 10px;', label: 'margin-top: 0px;'}"
                [iconAlignment]="'right'"
                (clicked)="onClick()"
                [width]="'100%'"
            ></app-button>
        </div>
        <div class="triangulo"></div>
    </div>
}
  