import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, booleanAttribute, forwardRef, inject } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import type { KeyValueSelect } from '@shared/interfaces/key-value-select.interface';

@Component({
  selector: 'so-select-grouped-reactive-form',
  templateUrl: './so-select-grouped-reactive-form.component.html',
  styleUrl: './so-select-grouped-reactive-form.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SoSelectGroupedReactiveFormComponent),
      multi: true
    }
  ]
})
export class SoSelectGroupedReactiveFormComponent implements OnChanges {
  @Output() selectionChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectionText: EventEmitter<any> = new EventEmitter<any>();

  @Input() selectLabel: string = '';
  @Input() selectedItemId: number | string | null = null;
  @Input() isLoading: boolean = false;
  @Input() customStyle: string = '';
  @Input() name: string = '';
  @Input({ transform: booleanAttribute }) isWhiteBox: boolean = false;
  @Input({ transform: booleanAttribute }) requiredMark: boolean = false;
  @Input() disabledKeys: any[] = [];
  @Input() isDisabled: boolean = false;
  @Input() isInitiallyEmpty: boolean = false; // Nueva propiedad
  @Input() tooltipDisabledText: string | null = '';
  @Input() formControl: FormControl | null = null;
  @Input() items: { [key: string]: KeyValueSelect[] } = {};
  @Input() display: 'vertical' | 'horizontal' = 'vertical';
  @Input() addSelectOption: boolean = false;

  // @ViewChild('selectElement') selectElementRef!: ElementRef;

  @Input() labelStyle: string = '';

  public elementRef = inject(ElementRef);

  value: any;

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor() {}

  get touched(): boolean {
    return this.formControl?.touched ?? false;
  }

  get valid(): boolean {
    return this.formControl?.valid ?? true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Si se cambian los items y ya no está el actual, reestablecer el id seleccionado
    if (this.isInitiallyEmpty && Object.keys(changes).includes('items')) {
      const currentValue: KeyValueSelect[] | undefined = changes['items'].currentValue;
      if (currentValue && !currentValue.some(item => item.key === this.selectedItemId))
        this.selectedItemId = null;
    }
  }

  shouldBeDisabled(key: any) {
    return this.disabledKeys.some(currentKey => currentKey == key);
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  updateValue(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    const newValue = selectElement.value;
    const selectedIndex = selectElement.selectedIndex;
    const selectedOption = selectElement.options[selectedIndex];
    const selectedText =  selectedOption.text;
    
    if (this.isInitiallyEmpty) this.selectedItemId = newValue;
    this.value = newValue;
    this.onChange(newValue);
    this.onTouched();
    this.selectionChange.emit(newValue);
    this.selectionText.emit(selectedText);
  }
  setDisabledState?(isDisabled: boolean): void {
    // Optional: Implementar si necesitas controlar el estado deshabilitado del select
    this.isDisabled = isDisabled;
  }
  getBotTypeKeys(obj: any): string[] {
    return Object.keys(obj);
  }
}
