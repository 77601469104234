import type { Observable } from "rxjs";
import type { DynamicComponentService, AlertType } from "@shared/components/atomics/body-loading/dynamic-component-service.service";

// Binding del servicio de componente dinámico a una función para abrir una alerta
export const openAlertFn = (dynamicComponentService: DynamicComponentService, messageField: 'message' | 'htmlContent') =>
  (
    title: string,
    message: string,
    alertType: AlertType,
    autohide: boolean,
    animate = true,
    showButtons = true,
    onAlertCloseCallback?: Function,
  ): Observable<boolean | { result: boolean, text: string }> =>
    dynamicComponentService.showAlert({
      title,
      alertType,
      autohide,
      animate,
      showButtons,
      [messageField]: message,
    }, onAlertCloseCallback);
