import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, booleanAttribute, forwardRef, inject } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import type { KeyValueSelect } from '@shared/interfaces/key-value-select.interface';

@Component({
  selector: 'so-select-reactive-form',
  templateUrl: './so-select-reactive-form.component.html',
  styleUrl: './so-select-reactive-form.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SoSelectReactiveFormComponent),
      multi: true
    }
  ]
})
export class SoSelectReactiveFormComponent implements OnInit, OnChanges {
  @Output() selectionChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectionText: EventEmitter<any> = new EventEmitter<any>();

  @Input() selectLabel: string = '';
  @Input() selectedItemId: number | string | null = null;
  @Input() isLoading: boolean = false;
  @Input() customStyle: string = '';
  @Input() displayValueStyle = '';
  @Input() displayValueClass = '';
  @Input() name: string = '';
  @Input({ transform: booleanAttribute }) isWhiteBox: boolean = false;
  @Input({ transform: booleanAttribute }) requiredMark: boolean = false;
  @Input() disabledKeys: any[] = [];
  @Input() isDisabled: boolean = false;
  @Input() isInitiallyEmpty: boolean = false; // Nueva propiedad
  @Input() tooltipDisabledText: string | null = '';
  @Input() formControl: FormControl | null = null;
  @Input() display: 'vertical' | 'horizontal' = 'vertical';
  @Input() addSelectOption: boolean = false;

  // @ViewChild('selectElement') selectElementRef!: ElementRef;

  @Input() labelStyle: string = '';

  public elementRef = inject(ElementRef);

  value: any;

  onChange: any = () => {};
  onTouched: any = () => {};

  private _items: any[] = [];
  private displayItems: { key: string | number, value: string | null }[] = [];
  public currentDisplayItem: string | null = null;

  constructor() {}

  ngOnInit() {
    // this.isInitiallyEmpty = this.selectedItemId === null; // Determinar si estaba vacío inicialmente
    if (this.selectedItemId !== null )
      this.currentDisplayItem = this.displayItems.find(item => item.key == this.selectedItemId)?.value ?? null;
  }

  get touched(): boolean {
    return this.formControl?.touched ?? false;
  }

  get valid(): boolean {
    return this.formControl?.valid ?? true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Si se cambian los items y ya no está el actual, reestablecer el id seleccionado
    if (Object.keys(changes).includes('items')) {
      if (this.isInitiallyEmpty) {
        const currentValue: KeyValueSelect[] | undefined = changes['items'].currentValue;
        if (currentValue && !currentValue.some(item => item.key === this.selectedItemId))
          this.selectedItemId = null;
      }
    }
  }

  shouldBeDisabled(key: any) {
    return this.disabledKeys.some(currentKey => currentKey == key);
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  updateValue(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    const newValue = selectElement.value;
    this.currentDisplayItem = this.displayItems.find(item => item.key == newValue)?.value ?? null;
    const selectedIndex = selectElement.selectedIndex;
    const selectedOption = selectElement.options[selectedIndex];
    const selectedText =  selectedOption.text;
    
    if (this.isInitiallyEmpty) this.selectedItemId = newValue;
    this.value = newValue;
    this.onChange(newValue);
    this.onTouched();
    this.selectionChange.emit(newValue);
    this.selectionText.emit(selectedText);
  }

  @Input({ required: true }) set items(value: any[]) {
    if (value === undefined) value = [];
    this._items = value.map((element, index) => {
      const key: any = Object.values(element)[0];
      const val = Object.values(element)[1];
      const displayValue = element?.display ?? null;
      this.displayItems.push({ key: key?.toString() ?? index, value: displayValue });
      return { key, value: val };
    });
    if (this.addSelectOption && this._items.length > 0) this._items.unshift({ key: '', value: 'Seleccionar' });
  }

  get items(): any[] {
    return this._items;
  }

  setDisabledState?(isDisabled: boolean): void {
    // Optional: Implementar si necesitas controlar el estado deshabilitado del select
    this.isDisabled = isDisabled;
  }
}
