@if (iconF) {
<mat-icon [style]="'transform: scale(' + size + ');' + style ">{{
  name
  }}</mat-icon>
} @else {

<app-so-svg-icon 
  [src]="iconsFolderPath + name + '.svg'" 
  [color]="color" 
  width="28" 
  height="28" 
  [size]="size" 
  [rotation]="rotation" 
  [applyChange]="applyChange" 
  [onlyPath]="onlyPath"
  [tooltip]="tooltip"
  >
</app-so-svg-icon>
}
